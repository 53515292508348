
<template>
  <div class="mt-n1">
    <actionButtons
      :currentTable="table"
      :actionButtonsArray="table['actionButtonTableItem']"
      :additional="{ selectedRows: selectedRows }"
      v-on="$listeners"
      :disabled="disabled"
    ></actionButtons>
  </div>
</template>
<script>
import actionButtons from "@/commonComponents/actionButtons.vue";
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  components: { actionButtons },
  props: ["table", "selectedRows"],
  computed: {
    disabled() {
      let disabled = false;
      if (Object.keys(this.selectedRows).length == 0) {
        disabled = true;
      }
      return disabled;
    },
  },
  methods: {
    closeMenu() {
      console.log("close");
      this.showMenu = false;
    },
  },
};
</script>